// import { Link } from 'gatsby';
import * as React from 'react';
// import { darken } from 'polished';
import { css } from '@emotion/core';
import { Link } from 'gatsby';

// import { colors } from '../styles/colors';
// import LeftNav from './icons/left-nav';

export interface PaginationProps {
  currentPage: number;
  numPages: number;
}

const navCss = css`
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.14;
  color: #939498;
  background-color: #efeded;
  padding-bottom: 60px;

  div {
    display: inline-block;
    margin-top: 30px;
  }

  span {
    padding: 9px;
    margin: 0px 17px;
  }

  a:hover:not(.active) {
    opacity: 0.8;
  }

  .right-nav {
    transform: rotate(180deg);
  }

  .inactive {
    opacity: 0.5;
  }
`;

const Pagination: React.FunctionComponent<PaginationProps> = ({ currentPage, numPages }) => {
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString();
  const nextPage = (currentPage + 1).toString();

  return (
    <nav css={navCss}>
      <div>
        {!isFirst && (
          <Link to={prevPage} title="previous page" rel="prev">
            <img src={require('../content/img/left-nav.png')} />
          </Link>
        )}

        {isFirst && <img className="inactive" src={require('../content/img/left-nav.png')} />}

        <span>
          {currentPage} of {numPages}
        </span>

        {!isLast && (
          <Link to={nextPage} title="next page" rel="next">
            <img className="right-nav" src={require('../content/img/left-nav.png')} />
          </Link>
        )}

        {isLast && (
          <img className="inactive right-nav" src={require('../content/img/left-nav.png')} />
        )}
      </div>
    </nav>
  );
};

export default Pagination;
